@import './stylesheets/variables';

@import './stylesheets/components/form';
@import './stylesheets/components/dropdown';
@import './stylesheets/components/button_group';
@import './stylesheets/components/button_icon';
@import './stylesheets/components/subtask';
@import './stylesheets/components/action_icon';
@import './stylesheets/components/datepicker';
@import './stylesheets/components/compact_select';
@import './stylesheets/components/logo';
@import './stylesheets/components/table';
@import './stylesheets/components/stage';
@import './stylesheets/components/notifications_dropdown';
@import './stylesheets/components/chat_popup';
@import './stylesheets/components/calendar';
@import './stylesheets/components/user_avatar';
@import './stylesheets/components/user_dropdown';
@import './stylesheets/components/user_thumbnails';
@import './stylesheets/components/message';
@import './stylesheets/components/content_group_form';
@import './stylesheets/components/content_label_selector';
@import './stylesheets/components/attachment_gallery';
@import './stylesheets/components/workspace_searchbar';
@import './stylesheets/components/stage_dropdown';
@import './stylesheets/components/tag';
@import './stylesheets/layout/general';
@import './stylesheets/layout/modal';
@import './stylesheets/layout/alert';
@import './stylesheets/layout/top_bar';
@import './stylesheets/layout/hover';
@import './stylesheets/layout/login_page';
@import './stylesheets/layout/user_profile';
@import './stylesheets/pages/content_popup';
@import './stylesheets/pages/content_group_list';
@import './stylesheets/pages/content_label_popup';
@import './stylesheets/pages/workspace_users_popup';
@import './stylesheets/pages/assigned_users_popup';
@import './stylesheets/pages/workspace_calendar';
@import './stylesheets/pages/user_activities';
@import './stylesheets/content_page-list';
@import './stylesheets/workspace_page-list';

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-ExtraLight.woff2) format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-Light.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-SemiBold.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-ExtraBold.woff2) format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-Black.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
}

.breadcrumb {
  color: $breadcrumb-inactive-color;
  letter-spacing: 0.5px;
}

.breadcrumb a {
  color: $breadcrumb-link-color;
  font-weight: bold;
}

.full_height {
  min-height: 600px;

  @media (min-width: $medium-breakpoint) {
    height: 100vh;
    height: 100dvh;
  }
}

.form-control {
  &:not(textarea):not([type=file]) {
    height: 43px;
  }

  width: 100%;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px;
}

input.form-control:hover,
select.form-control:hover,
.dropdown.form-control:hover {
  border: 1px solid $input-focus-border-color;
  background-color: #f6f7fb;
}

.form-control:focus {
  box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25) !important;
}

:focus {
  outline: 0px solid pink !important;
}

:focus:not(:focus-visible) {
  outline: 0 !important;
}

:focus-visible {
  outline: 0px solid blue !important;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.pading-content {
  padding: 30px 45px
}

main {
  min-height: calc(100vh - 130px);
  min-height: calc(100dvh - 130px);
}

.container.container--workspace-list {
  max-width: 100%;
  padding: 0;

  @media (min-width: $large-breakpoint) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
  }

  @media (min-width: 1400px) {
    //max-width: 1600px;
  }
}

.auth_content main {
  height: 100vh;
  height: 100dvh;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
}

button:focus {
  outline: none;
  box-shadow:none !important;
}

a:focus:not(:focus-visible) {
 outline: none !important;
}

*:focus {outline:none !important}

.input-group .input-group-append .input-group-text {
  height: 43px;
}

.password-input input {
  padding-right: 3rem;
}

.password-type-option {
  position: absolute;
  right: 4px;
  z-index: 1024;
  cursor: pointer;
  color: #8d8e9d;
  padding: 10px;
}


.close_modal {
  position: absolute;
  right: 15px;
  font-size: 13px;
  top: 15px;
}

.custom_body {
  padding: 40px 0px !important;
}

.upgrade_modal.modal{
  .modal-content{
    background: none;
    border: none;
  }

  .upgrade_links-container{
    position: absolute;
    bottom: 10px;

    p{
      margin-bottom: 0.5rem;

      @media (min-width: $large-breakpoint){
        font-size: 30px
      }
    }

    a{
      color: #721C8B;
      text-decoration: underline;
    }

    .btn.btn-link{
      font-size: 14px;
      color: #6E7480;

      @media (min-width: $large-breakpoint){
        font-size: 26px
      }
    }

    @media (min-width: $large-breakpoint){
      bottom: 20px;

      p{
        margin-bottom: 1rem;
      }
    }
  }
}

.lg {
  height: 60px;
}

.form-error {
  &::first-letter{
    text-transform: capitalize;
  }

  position: absolute;
  bottom: -18px;
  font-size: 12px;
  color: #dc3545;
}

.btn-link {
  text-decoration: auto;
}

.btn-link.active {
  text-decoration: auto;
  font-weight: bold;
}

.pd-75 {
  padding-top: 75px
}

.inner-app {
  padding: 35px
}

@media (min-width: $large-breakpoint){
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    right: 22px;
  }
}

.white-space-no-wrap{
  white-space: nowrap;
}

.selected {
  color: #fff !important;
  background: #1d2636 !important;
}

.pl-2{
  padding-left: 2rem !important;
}

.user-name {
  margin-top: 0px;
}

.link-button {
  text-decoration: none;
  background: #000;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}

.error_message {
  color: #dc3545;
  font-size: 14px;
}

.full-width  {
  width: 100%;
}

.page_loading {
  top: 75px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.font-15 {
  font-size: 15px
}

.addmore_btn {
  color: #198754;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
}

.remove_btn {
  font-size: 12px;
  text-transform: uppercase;
  background: #dc3545;
  padding: 4px 0px 3px ;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 10px;
  cursor: pointer
}

.role_div  span{
  font-size: 14px;
  margin-right: 10px;
}

.offcanvas-start {
  width: 355px !important;
}

.pl-0 {  padding-left: 0 !important; }
.pl-1 {  padding-left: 2px }
.pl-2 {  padding-left: 5px }
.pl-3 {  padding-left: 10px }
.pl-4 {  padding-left: 1rem }

.ml-auto{ margin-left: auto;}

.ml-1 {  margin-left: 2px }
.ml-2 {  margin-left: 5px }
.ml-3 {  margin-left: 10px }
.ml-4 {  margin-left: 1rem }

.mr-1 {  margin-right: 2px }
.mr-2 {  margin-right: 5px }
.mr-3 {  margin-right: 10px }
.mr-4 {  margin-right: 1rem }

.mt-10 {
  margin-top: 10px
}

.ml-2 {  margin-left: 5px }

@media (min-width: $large-breakpoint){
  .pl-lg-2 {
    padding-left: 5px !important;
  }

  .pl-lg-12 {
    padding-left: 3rem !important;
  }

  .mt-lg-0{ margin-top: 0;}

  .mr-lg-2 {
    margin-right: 1rem;
  }
}

i.small-icon{
  font-size: 100px;
}

i.big-icon{
  font-size: 300px;
}

.hidden {
  display: none;
}

.table > tbody > tr {
  vertical-align: middle;
}

.table-actions {
  display: flex;
  gap: 0.5rem;
}

#basic-nav-dropdozwn.dropdown-toggle::after,
#dropdown-basic.dropdown-toggle::after {
  display: none !important
}

.layout__container-wrapper {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.layout__adaptive-wrapper {
  padding: 1rem;
}

.upload_preview {
  width: 100px;
  // height: 100px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;
}

.edit_profile img {
  width: 100px;
  // height: 100px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;
}

@import "~bootstrap/scss/bootstrap";
@import './stylesheets/pages/user_activities';
@import './stylesheets/components/sidebar';

.btn-upgrade{
  background-image: linear-gradient(to bottom right, #ef8128, #f59d87);
  background-image: linear-gradient(to bottom left, #A63CDA, #D598E8);
  color: white !important;
  border: none;
  padding: 1rem 1.25rem;
  text-align: left;

  &.btn-sm{
    border-radius: 20px;
    font-size: 12px;
    padding: 0.3rem 0.8rem;
  }
}

.btn-add-business{
  background-image: linear-gradient(to bottom right, #ef8128, #f59d87);
  color: white !important;
  border: none;
  padding: 1rem 1.25rem;
  text-align: left;
}


.btn-gray{
  background-color: #c8ccdb;
}

.navbar {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #eee;
}

.btn-primary,
.btn-primary:focus {
  color: #fff !important;
  background-color: #68C5AA;
  border-color: #68C5AA;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: rgba(#68C5AA, 0.75);
  border-color: rgba(#68C5AA, 0.75);
}

.btn-primary, btn-primary:hover {
  color: #fff !important
}

.btn-brand,
.btn-brand:focus {
  color: #fff;
  background-color: #fe543d;
  border-color: #fe543d;
}

.btn-brand:hover {
  color: #fff;
  background-color: #fe543d;
  border-color: #fe543d;
}

.btn-hollow,
.btn-hollow:hover {
  background-color: transparent;
  border-color: transparent;
}

.btn-hollow, .btn-hollow:hover {
  color: #333;
}

.nav-link {
  color: #1d2636;
  padding: 0.5rem 0rem;
}

.nav-tabs .nav-link {
  padding: 0.5rem 1rem;
}

.text-xs {
  font-size: 13px;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.font-weight-bold {
    font-weight: 700!important;
}

.font-weight-black {
    font-weight: 900!important;
}

.border-left-primary {
  border-left: .25rem solid #4e73df!important;
}

.border-left-info {
  border-left: .25rem solid #36b9cc!important;
}

.border-left-success {
  border-left: .25rem solid #1cc88a!important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 5px;
  overflow: hidden;
}

.img-profile {
  height: 2rem;
  width: 2rem;
}

@import
url("https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");

body {
  background-color: #f4f1ec;
  color: #2b2f3f;
  touch-action: manipulation;
}

.dropdown-menu:not(.show) {
  right: 0 !important
}

ol, ul {
  padding-left: 0px;
  margin-bottom: 0px
}

.sidebar {
  height: 100vh;
  height: 100dvh;
  left: 0;
  top: 0;
  z-index: 99;
  position: fixed;
  width: 200px;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  background-color: #2b2f3f;
}

select.form-control { -webkit-appearance: menulist; }

.header_iner  {
  background-color:#303041;
  color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 99;
  padding: 6px;
  position: relative;
  border-radius: 0px;
  margin: 0;
  border-radius: 0;
  height: 50px;
  border-bottom: 0px solid #eee;
  padding: 0 10px;
}

.header_iner .nav-link{
  color: #ffffff;
  text-align: center;
}

.header_iner .nav-link:hover,
.header_iner .nav-link:focus {
  color: #ffffff;
}

.main_content .main_content_iner {
  position: relative;
  //background: #fff;
  margin: 0;
  z-index: 22;
  border-radius: 0px;
  min-height: calc(100vh - 58px);
  min-height: calc(100dvh - 58px);
}

.main_content {
  padding-left: 200px;
  width: 100%;
  padding-top: 0px !important;
  position: relative;
  min-height: 100vh;
  min-height: 100dvh;
  margin-bottom: -40px;
}

.auth_content {
  margin-bottom: -40px
}

.container-fluid:not(.d-flex):not(.no-user-alert).position-relative{
  min-height: calc(100vh - 58px);
  min-height: calc(100dvh - 58px);
  padding-bottom: 0px
}

.auth_content .container {
  padding: 3rem 2rem;
  border-radius: 5px
}

.padding-inner {
  padding-left: 42px
}

.file_attached {
  width: 360;
  margin-right: 40px;
}

.inline_edit_input {
  display: block;
  position: relative;
  margin-bottom: 0;
  border-radius: 5px;
  cursor: pointer;
  white-space: pre-wrap;
}

.table-responsive{

  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

.stack-table {

  table{

    td{
      height: 45px;
      padding: 0px 10px;
    }

    th {
      height: 45px;
      padding: 0px;
    }

    td.title-column{
      padding: 1rem;
      max-width: 200px;
      min-width: 200px;
    }

    td.due_date-column,
    td.updated_at-column{
      color: #8D8E9D !important;
    }

    td:not(.title-column){
      //max-width: 200px;
      //text-align: center;
    }

    thead{
      th{
        &.content-group-table__title{
          text-align: center;
          font-size: 0.8rem;
        }

        .inline_edit_input{
          width: 180px;
          font-weight: bold;
        }
      }
    }

    td.add-content-column{
      text-align: left;
      background-color: #F7F6FB;

      button.button-content-add {
        padding-left: 0;
        padding-right: 0;
        font-size: 0.9rem;
        color: #8D8E9D !important;
        background: none;
        border: none;

        .button-icon__container{
          height: 16px;
          width: 16px;
          margin-right: 0.4rem;

          svg{
            height: 100%;
            width: 100%
          }
        }
      }
    }
  }

  .btn-title{
    color: #fe543d !important;
    text-align: left;
  }
}

.stack-table .btn:not(.button-content-add):not(.btn-title):not(.btn-more_participants):not(.dropdown-item):not(.btn-primary):not(.btn-secondary):not(.btn-hollow):not(.dropdown-toggle){
  background: none;
  border: none;
  color: #8D8E9D !important;
  font-size: 1.5rem;
  line-height: 1;
}

.stack-table i{
  color: #8D8E9D;
}

.stack-table .btn.button-content{
  background: none;
  border: none;
  color: black !important;
}

.stack-table table thead th{
  background: none;
  border: none;
}

.stack-table table thead tr{
  border-width: 0 !important;
}

@media (max-width: 991px){
  .sidebar {
    left: -280px;
    -webkit-transition: 0.5s;
    transition: 0.4s;
    top: 0;
    width: 280px;
  }

  .padding-inner {
    padding-left: 0px
  }

  .file_attached {
    margin-right: 0px;
    margin-bottom: 10px
  }

  .mobile-none {
    display: none
  }

  .user-name {
    margin-left: 0;
  }

  .stack-table table thead th {
    //display: none;
  }

  .stack-table table td,.stack-table th {
      //display: block;
  }

  .stack-table td[data-th]:before  {
      //content: attr(data-th);
      //font-weight: 700;
  }

  .chat-message {
    width: 100%;
    left: 0;
    right: 0;
  }

  .ck.ck-editor {
    width: 87% !important;
  }

  .chat-popup {
    left: 0;
    right: 0px !important;
    width: 100%;
  }
}

@media (max-width: $large-breakpoint) {
  .active_sidebar .sidebar{
    left: 0;
    box-shadow: 0 5px 20px rgba(54, 52, 52, 0.2);
  }

  .main_content {
    padding: 120px 0 100px 0;
  }

  .main_content .main_content_iner {
    //padding: 10px 0px;
  }
}

@media (min-width: $large-breakpoint){
  .d-lg-none {
    display: none!important;
  }

  #desktop_wrapper .main_content {
    padding-left: 0px;
    transition: all 0.3s ease;
    padding-left: 60px;
  }

  #desktop_wrapper .sidebar {
    padding-left: 0px;
    transition: all 0.3s ease;
    width: 60px;
  }

  #desktop_wrapper .admin-menu span {
    display: none;
  }

  .desktop_active .sidebar{
    height: 100vh;
    height: 100dvh;
    left: 0;
    top: 0;
    z-index: 99;
    position: fixed;
    width: 240px !important;
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
    background: #303041;
  }

  .desktop_active .admin-menu span{
    display: block!important;
  }

  .desktop_active .main_content {
    padding-left: 240px !important;
    transition: all 0.3s ease;
  }
}

.admin-menu {
  padding-top: 20px;
  height: 90vh;
}

.admin-menu .close-sidebar{
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.sidebar .logo {
  margin: 0px 0px;
  background: #303041;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  color: #fff;
  padding: 0px 15px;
  font-size: 18px;
}

.sidebar a:hover{
  color: #8e919e;
}

.sidebar_icon, .sidebar_close_icon {
  margin-right: 1rem;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
}

.admin-menu li > a {
  padding: 10px 10px;
  margin: 8px 10px;
  border-radius: 5px;
  color: #ddd;
  display: flex;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.admin-menu li > a i {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  margin-left: 0.15rem;
}

.admin-menu li > a:hover {
  color: #fff;
}

.nav-link:hover, .nav-link:focus {
  color: #1d2636
}

.widget_a {
  color: #666666;
}

.widget_a:hover {
  color: #111111;
}

.main_content_iner {
  //margin-top: 15px
}

.logo_link{
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}

.logo a:hover {
  color: #fff;
  width: 200px
}

.sidebar .logo:hover {
  color: #fff;
}

.top-65 {
  top: 65px !important;
}

.hr_line {
  height: 1px;
  background: #ddd;
  margin-bottom: 25px;
  margin-top: 30px;
  border-radius: 0px;
}

.content-card {
  border :1px solid #eee;
  width: 100%;
  margin-bottom: 7px;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.card-image img {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
}

.card_header {
  border-bottom: 1px solid #eee;
  padding: 3px 10px;
}

.card-image {
  padding: 10px;
  height: 120px;
}

.content-body{
  //border-top: 1px solid #eee
}

.content-description {
  margin-top: 10px;
  font-size: 14px;
  padding-bottom: 12px;
  border-radius: 0.25rem;
  border: 1px solid transparent;

  & > ul,
  & > ol {
    margin-top: 1rem;
  }

  ul,
  ol {
    margin-left: 3rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    overflow-wrap: break-word;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.content-description.content-description--editable {
  padding: 12px;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  cursor: text;
  font-size: 14px;
}

.description-section textarea.form-control-custom {
  padding: 12px;
  background-color: #fff;
  border-radius: 0.25rem;
  font-size: 16px;

  @media (min-width: $large-breakpoint) {
    font-size: inherit;
  }
}

.content-feature {
  padding: 3px 10px;
}

.contant-a, .content-created {
  font-size: 13px;
}

.center-secton img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.version-center .center-secton img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain
}

.left-full-height {
  //background: #f8f9fa;
  padding: 7px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  //border: 1px solid #dee2e6;
  border-radius: 3px;

  .no-content_container{
    background-color: #F7F6FB;
    border-radius: 10px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    color: #A1A1AF;
  }
}


.chat_section {
  //background: #f8f9fa;
  margin-top: 8px;
  //border: 1px solid #dee2e6;
  max-height: calc(100vh - 200px);
  max-height: calc(100dvh - 200px);
  border-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  //transform:rotateX(180deg);
  //-moz-transform:rotateX(180deg); /* Mozilla */
  //-webkit-transform:rotateX(180deg); /* Safari and Chrome */
  //-ms-transform:rotateX(180deg); /* IE 9+ */
  //-o-transform:rotateX(180deg); /* Opera */

  @include media-breakpoint-up(lg) {
      max-height: calc(100vh - 200px);
      max-height: calc(100dvh - 200px);
  }
}

.without_tabs_sction {
  max-height: calc(100vh - 240px) !important;
  max-height: calc(100dvh - 240px) !important;
}

.chat-list {
  list-style: none;
  //transform:rotateX(180deg);
  //-moz-transform:rotateX(180deg); /* Mozilla */
  //-webkit-transform:rotateX(180deg); /* Safari and Chrome */
  //-ms-transform:rotateX(180deg); /* IE 9+ */
  //-o-transform:rotateX(180deg); /* Opera */

  .infinite-scroll-component > div {
    margin-bottom: 8px;
  }
}

.content-group__chat-panel .user-dropdown {
  overflow-y: auto;
  margin-top: 5px;
  margin-left: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 330px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.chatbtn-group .tabs ul li {
  background-color: #fff;
  border-color: #54e3f0;
}

.chatbtn-group .btn {
  border-radius: 5px;

  &:not(.btn-success):not(.btn-danger){
    //padding: 5px;
    //font-size: 12px;
    //background: #e8f1f3;
    //border: 1px solid #ddd;
    //color: #222;
  }

  &.active{
    color: white !important;
    background-color: #fe543d !important;
  }
}

.full-width {
  width: 100%
}

.float-right {
  float: right;
}

.highlight-card {
  border: 1px solid #ef503a;
}

.tabs ul {
  //background: #eee;
  border-radius: 3px;
}

.tabs ul > li {
  padding: 2px 5px;
  font-size: 13px;
  cursor: pointer;
  color: #8D8E9D;
}

.tabs > ul > .active {
  background-color: #2b2f3f;
  color: #fff;
}

.tabs > ul > li:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.tabs > ul > li:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.mt_10 {
  margin-top: -10px
}

.content-group__chat-toggle-activities {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  font-size: 0.9rem;
  text-align: right;

  a {
    color: #777;
  }

  a:hover {
    color: $body-color;
    cursor: pointer;
  }

  a img {
    margin-right: 0.3rem;
    vertical-align: sub;
  }
}

.login-form__button-group .btn {
  display: block;
  padding: 0;
}

/*
  Overlay
*/
#transparent_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

/*
  Load Spinner
*/
#overlay,
#chat_overlay {
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background: black;
  opacity: .5;
  z-index: 100;
}

#chat_overlay{
  position: absolute;
  opacity: .25;
  border-radius: 5px;

  .spinner-border{
    top: inherit;
    left: inherit;
  }
}

#overlay .spinner-border{
  top: 40%;
  height: 50px;
  width: 50px;
  position: relative;
}

.alert-container{
  background: none !important;
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 500px;
  z-index: 99;
}

.spinner-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.tooltip {
  z-index: 1151 !important;
  opacity: 1 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0 !important;
  max-height: 300px;

  .tooltip-arrow{
    display: none;
  }

  .tooltip-inner{
    background-color: #fff;
    color: black;
    padding: 12px;

    .btn-close{
      font-size: 12px;
    }

    .tooltip-text{
    }
  }
}

.chat-toggle {
  width: 45px;
  height: 45px;
  background-color: #ffc05f;
  border-radius: 100px;
  position: absolute;
  right: 20px;
  bottom: 10px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}

.chat-message {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1024;
}

.word-break {
  word-break: break-all;
}

.ck.ck-editor {
  width: 84% !important;
  margin-right: 5px !important;
}

.other_roles .custom-editor .ck.ck-editor {
  width: 77% !important;
}

.other_roles .title-top {
  margin-top: 0px;
}

.form-errors input {
  border-color: #ff0000;
}

.chat-right {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.chat-left {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
}

.show-count {
  font-size: 20px;
  font-weight: 600;
}

.chat-username {
  color: #fff;

}

.django-ckeditor-widget {
  width: 100% !important;
}

.ck-toolbar_grouping {
  height: 28px !important;
}


.ck.ck-editor__editable_inline>:last-child {
  margin-bottom: 7px !important;
}

.ck.ck-editor__editable_inline>:first-child {
  margin-top: 7px !important;
}

.ck.ck-editor__editable_inline {
  height: 50px !important;
}

.unknown-cursor {
  pointer-events: none;
  background: #ddd;
  opacity: 0.2;
}

.button-padding-0 button{
  padding: 0;
  font-size: 14px;
  margin-top: -5px;
  margin-bottom: 5px
}

.custom-grid .col-xl-3 {
  flex: 0 0 auto;
  width: 28%;
}

.custom-grid .col-xl-6 {
  flex: 0 0 auto;
  width: 44%;
}

.custom-editor .ck.ck-editor {
  width: 80% !important;
}

.title-top {
  margin-top: 30px;
}

#other_roles .left-full-height {
  height: calc(100vh - 220px);
  height: calc(100dvh - 220px);
}

.auth_content {
  background: #fff
}

// -----
// Kanban Board
// -----

.kanban-board-stages{
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;

  .kanban-card-group--empty .add-content_container {
    margin-top: 0;
  }

  .kanban-card-group{
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 100%;
    padding-left: 10px;
    padding-right: 5px;
    border-radius: 5px;

    &.add-button{
      min-width: 300px;
      min-height: 500px;
      background: none;
    }

    .kanban-card-group_header{
      //min-height: 40px;

      .h4{
        max-height: 45px;
        display: block;
        position: relative;
        margin-top: 0px;
        margin-bottom: 0;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        max-width: 220px;
        width: 180px;

        span{
          display: block;
          float: right;
        }
      }

      .inline_edit_input{
        max-height: 45px;
        display: block;
        position: relative;
        margin-top: 0px;
        margin-bottom: 0;
        font-size: 1rem;
        border-radius: 5px;
        cursor: pointer;
        white-space: pre-wrap;
        width: auto;
        max-width: 220px;
        margin-right: 0;
        margin-left: 0;
        width: 200px;
      }

      button:not(.dropdown-item){
        font-size: 1.5rem;
        padding: 0;
        background: none !important;
        border: none !important;
        color: #8D8E9D !important;
        position: relative;
        height: 40px;
        width: 40px;

        span{
          margin: 0;
          position: absolute;
          top: 35%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .infinite-scroll-component__outerdiv{
      height: 100% !important;
    }

    @media (min-width: $large-breakpoint) {
      width: 280px;
      padding-right: 10px;
    }
  }
}

.inline_edit_input.disableable{
  &:read-only{
    background-color: transparent;
  }

  &:focus:not(:read-only){
     box-shadow: 0 0 0 1px #00FFFF; /* or whatever colour you'd prefer */
  }

  border: 1px solid transparent;
}

.inline_edit_input[contenteditable="true"]:focus{
  background-color: white;
}


.kanban-card{
  &.kanban-card--no-preview .kanban-card_body {
    padding-top: 0;
  }

  padding-bottom: 8px;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.add-content_container{
    text-align: left;
    background-color: #F7F6FB;
    margin: 10px 0 8px;
    padding: 5px 10px;

    .button-content-add {
      background: none !important;
      color: #8D8E9D !important;
      border: none;
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      text-align: left;
    }

    .inline_edit_input{

      @media (max-width: $large-breakpoint) {
        font-size: 1rem;
      }
    }
  }

  .kanban-card_header{
    padding: 12px 12px 4px;
    min-height: 40px;
    margin-bottom: 5px;
    white-space: normal;
    font-size: 15px;
    font-weight: 500;

    &:hover{
      cursor: pointer;
    }
  }

  .kanban-card_image{
    max-height: 268px;
    overflow: hidden;
    text-align: center;

    img{
      max-height: 268px;
      object-fit: contain;
    }

    i{
      font-size: 50px;
    }
  }

  .kanban-card_body{
    white-space: normal;
    padding: 12px;
    padding-bottom: 0;
    min-height: 20px;

    .kanban-card_body-date{
      font-size: 13px;
      line-height: 30px;
    }
  }
}

.kanban-card__participants-wrapper--full {
  width: 100%;
}

.kanban-card__participants-wrapper .attribute-icon {
  //margin-top: 7px;
}

.kanban-card__participants-wrapper .user-thumbnails {
  flex-wrap: wrap;
  
}

.kanban-card_body .project-label,
.kanban-card_body .content-label {
  //margin-top: 2px;
  //margin-bottom: 3px;
}

.kanban-card_body .attribute-icon {
  //margin-top: 5px;
  //margin-bottom: 7px;
  height: 12px !important;
  width: 12px !important;
}

.kanban-column__no-cards {
  padding: 16px;
  font-size: 15px;
  font-weight: 400;
  background-color: #fff;
}

.add-stage_button{
  margin-top: 8px;
  background-color: #F7F6FB;
  color: #8D8E9D !important;
  border: none;
}

.ck.ck-editor__main{
  ul{
    list-style: disc;
    margin-left: 1rem;
  }

  ol{
    list-style: decimal;
    margin-left: 1rem;
  }
}

.vertical-scroll_thin,
.toastui-calendar-see-more,
.MuiMultiSectionDigitalClockSection-root{
  min-height: 1px;
  overflow-x: hidden !important;

  &.no-bg{
    overflow-y: scroll !important;
    width: calc(100% + 8px);
    scrollbar-gutter: stable;

    @supports (-moz-appearance:none) {  
      overflow-y: auto !important;
      padding-right: 8px;
      scrollbar-width: thin;
      scrollbar-gutter: auto;
      scrollbar-color: #8D8E9D transparent;
    }
  
  }

  &:not(.no-bg):not(.contents-container):not(.MuiMultiSectionDigitalClockSection-root) {
    padding-right: 6px;
    margin-right: -6px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &.hidden-scroll::-webkit-scrollbar{
    display: none;
  }

  &:not(.no-bg)::-webkit-scrollbar-track {
    background: #ededed;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #8D8E9D !important;
    border-radius: 10px;
  }
}

.horizontal-scroll_thin{
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb:horizontal{
    background-color: #8D8E9D;
    border-radius: 10px;
  }
}

.form-select.transparent{
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>");

  option{
    color: black;
  }
}

.more-users{
  position: relative;
  display: inline-block;
  .btn-more_participants{
    background-color: white !important;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;

    i{
      position: inherit !important;
    }
  }
}

.more-users_panel{
  background-color: white;
  padding: 1rem;

  img{
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }

  .margin-right-align {
    margin-right: 10px
  }

  ul{
    text-align: left;
    list-style: none;
  }
  li {
    margin-bottom: 10px
  }
}

.custom-tooltip{
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-transparent{
  background: none !important;
  color: black !important;
  border: none !important;
}

.btn-transparent:hover {
  background: none !important;
  color: black !important;
  border: none !important;
}

.validation_div {
  position: relative;
}

.margin-right-align {
  margin-right: 2px
}

@media (min-width: $large-breakpoint) {
  .profile_modal .modal-dialog{
    max-width: 650px;
  }
}

.login-password-div .form-error {
  bottom: inherit;
}

.password-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.file-upload-div {
  .crop-btn {
    background: #fe543d;
    display: table;
    border-radius: 3px;
    padding: 3px 5px;
    color: #fff;
    font-size: 14px;
  }
  .crop-load-div {
    margin: 10px 0px;
    cursor: pointer;
  }

  .crop-img {
    border-radius: 100px;
    border: 1px solid #ddd;
    width: 100px;
    height: 100px;
  }

  .ReactCrop__image {
    border: 1px solid #ddd;
    border-radius: 2px;
  }
}

.border-radius {
  border-radius: 50%;
  max-width: initial;
}

.permission-table th {
  padding: 0.5rem 0.5rem;
  border-width: 1px;
  background: #eee;
}

.dropdown-menu:not(.sm) {
  min-width: 12rem
}

.dropdown-menu.sm {
  min-width: 8rem;
  font-size: 0.875rem;
}

.attribute-icon {
  margin-right: 0.4rem;
  display: inline-block;
  height: 16px;
  vertical-align: middle;
  float: left;
  width: 16px;
  fill: rgba(114, 117, 128, 1);

  &.participant-icon,
  &.label-icon,
  &.due_date-icon,
  &.stage-icon,
  &.revision_count-icon{
    height: 14px;
    width: 14px;
  }
}

.content-group__approval-button-actions-dropdown .btn-group .btn {
  margin-left: 1px;
  border-radius: 0;
  font-weight: bold;
}

.content-group__approval-button-actions-dropdown .btn-group .btn:hover,
.content-group__approval-button-actions-dropdown .btn-group .btn:disabled,
.content-group__approval-button-actions-dropdown .btn-group .btn:focus {
  color: white;
  border-color: transparent;
}

.content-group__in-board-stage-divider {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  font-size: 0.9rem;
  font-weight: bold;
}

.content-group__toolbar-buttons {
  display: flex;
  &:not(.add-content){
    top: 0;
    right: 0px;
    position: absolute;
  }

  .btn{
    margin-right: 0.8rem;
    font-size: 0.8rem!important;
    background-color: #fff;
    color: black;
    height: 30px;
    width: 30px;
    padding: 0;

    &.btn-delete{
      img{
        height: 17px;
      }
    }

    &.btn-replace{
      img{
        height: 16px;
      }
    }

    @media (min-width: $large-breakpoint) {
      padding: 5px;

      &.btn-delete,
      &.btn-replace{
        img{
          height: 14px;
        }
      }
    }
  }

  .btn:not(.add-file){
    background-color: rgba(38, 38, 38, 0.7);

    @media (min-width: $large-breakpoint) {
      background-color: #8D8E9D;
    }
  }
}

@media (min-width: $large-breakpoint) {
  .content-group__toolbar-buttons {
    &:not(.add-content){
      right: -95px;
    }
  }
}

.image-gallery-slide-wrapper {
  margin-bottom: 1rem;
}

.image-gallery-thumbnails-wrapper {
  margin-bottom: 1rem;
}

.image-gallery-slide img {
  width: 100% !important;
  border-radius: 5px;

  @media (min-width: $large-breakpoint) {
    height: 55vh !important;
  }
}

.image-gallery-slide .image-gallery-image{
  object-fit: contain;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 120px !important;
  width: 30px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  max-height: 50px;
  max-width: 100%;
  width: initial;
  border-radius: 3px;
}

.empty_card_group  {
  width: 55px !important;
  background: #dddd;
  min-width: 55px !important
}

.role_select_options, .dropdown_option select {
  font-size: 0.81rem;
}

.admin_role {
  font-size: 14px;
  background: #727380;
  color: #fff;
  margin-bottom: 0.5rem;

  @media (min-width: $large-breakpoint) {
    margin-bottom: 0.25rem;
  }
}

.accepect-invitation-label {
  color: #68C5AA;
  font-size: 0.85rem;
  font-weight: 800;
}

.epr-preview {
  display: none !important;
}

.emoji_picker {
  position: absolute;
  bottom: 50px;

  .EmojiPickerReact {
    width: 365px !important;
  }
}

.content-group__approval-button-actions-dropdown {
  .btn {
    padding: 5px;
  }

  .dropdown {
    > button:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .dropdown-toggle-split {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 0px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dropdown-menu {
      transform: translate(0px, 41px) !important;
      padding: 0px;
      border-radius: 4px;
      border: 0;
      min-width: initial;
      border-radius: 0;

      .dropdown-item {
        padding-top: 7px;
        padding-bottom: 7px;
        margin: 0;
        width: 100%;
        color: #fff;
      }
    }
  }
}

.drag-icons-td {
  position: relative;
}

.drag-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25px;
  min-width: 25px;
  background-color: $color-tool-bg;

  @media (min-width: $large-breakpoint) {
    position: absolute;
    height: 100%;
    left: -30px;
    top: 0;
    padding: 10px 0;
    opacity: 0;
  }
}

.hovet-tr:hover .drag-icons {
  opacity: 5
}

.data_picker {
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px 14px;
    font-size: 14px;
  }
}

.tui-full-calendar-near-month-day {
  cursor: pointer
}

.label_form {
  .form-control {
    height: 30px;
    font-size: 13px;
    padding: 8px;
    background-color: #f7f7fb;
  }

  .form-control:hover {
    background-color: #c3c3c6;
  }

  .form-buttons {
    margin-top: 14px;
  }

  .btn {
    font-size: 12px;
    padding: 0px 10px;
    height: 30px;
  }
}

.content__details-detail{
  .detail.no_access{
    .user-thumbnails{
      cursor: auto !important;

      .custom-tooltip{
        .user-avatar{
          cursor: auto !important;
        }
      }
    }
  }
}

.content_group_icon_status-icon {
  display: inline-block;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  font-size: 0.7em;
  line-height: 1.42em;
  vertical-align: middle;
  text-align: center;
  background-color: #333;
  color: #fff;
  font-weight: bold;
  padding: 2px;
}

.content_group_icon_status-icon.submitted,
.content_group_icon_status-icon.approved,{
  background-color: $color-success;
}

.content_group_icon_status-icon.rejected{
  background-color: $color-danger;
}


.right-line{
  @include media-breakpoint-up(md) {
    border-right: 1px solid #8D8E9D;
  }
}

.px-7{
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}

.w-90{
  width: 90% !important;
}

.w-85{
  width: 85% !important;
}

.w-80{
  width: 80% !important;
}


// override calendar pop up shadow
.MuiPaper-root{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.google-container{
  iframe{
    margin: auto !important;
  }
}

.beta_superscript{
  font-size: 0.5em;
  top: -0.9em;
}

.break-word{
  overflow-wrap: break-word;
}

.stage-list__modal,
.reminder__modal,
.assigned-users-popup__modal,
.content-label__modal{
  .stage-list__modal__title{

  }

  .modal-title{
    @media (max-width: $large-breakpoint) {
      font-size: 16px;
    }
  }

  .modal-header{
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  .modal-body{
    padding: 0.5rem;
  }

  .stage-list__modal__list
  {
    max-height: 300px;
  }

  .modal-list{
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  .modal-header{
    border: none;
  }

  .modal-content{
    background-color: #fff;
  }
}

.reminder__modal{
  li{
    font-size: 14px;
    margin-bottom: 1rem;
    font-weight: 500;
  }
}

.stage-list__modal__list{
  font-size: 14px;
  li{
    height: 24px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
}

.assigned-users-popup__modal{
     img.rounded-circle{
      @media (max-width: $large-breakpoint) {
        height: 32px !important;
        width: 32px !important;
      }
    }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.inline_date_date.disabled{
  color: grey;
}

.MuiFormControl-root{
    width: 100%;
}

.loading.calendar-wrapper.layout__adaptive-wrapper{
  opacity: 0.7;
}
