.subtask-section{
  border-top: 1px solid #ddd;
}

.btn{
  &.btn-subtask{
    font-size: 14px;
    color: $color-dark-gray !important;
    font-weight: 400;
    border: none;

    &.btn-subtask-create{
      padding: 6px;
      padding-right: 12px;
      background-color: $color-ghost-white;
    }

    &.btn-subtask-delete,
    &.btn-subtask-visibility{
      font-size: 12px;

      &:hover, 
      &:active{
        background-color: $color-ghost-white;
      }
    }

    &.btn-subtask-item-create{
      border-radius: 5px !important; 
      background-color: $color-ghost-white;
      //height: 34px;

      &:hover{
        background-color: $color-bright-gray;
      }
    }
  }
}

.subtask-form{
  padding: 12px;
  padding-top: 0px;
  padding-bottom: 0px;

  &.subtask-form-button{
      .subtask-form-input{
        background-color: $color-ghost-white;
        border-radius: 5px;

        .form-control, 
        .btn-subtask-create,
        .subtask-form-icon{
          background-color: transparent !important;
        }

        &:hover{
          background-color: $color-bright-gray;
      }
    }
  }

  &.subtask-form-input_active{
    background-color: #fff;
    border-radius: 5px;
    
    .subtask-form-icon{
      background-color: #fff;
    }
  
    .form-control{
      background-color: #fff !important;
      border: none;
    }
  }

  &.subtask-form-title{
    .subtask-form-icon,
    .subtask-title{
      background-color: transparent;
    }
  }

  .form-control{
    padding-left: 6px;
    height: auto !important;
  }

  .subtask-form-icon,
  .subtask-checkbox{
    padding-right: 6px;
  }
}


.subtask-item-form{
  font-size: 14px;
  border-radius: 5px;

  &.subtask-item-form_active{
    background-color: #fff;
    
  }
  .btn-close{
    opacity: 0.4 !important;
  }

  @media (min-width: $large-breakpoint){
    .btn-close{
      display: none;
    }
  
    &:not(.subtask-item-form-button):not(.subtask-item-form-input_active):not(.subtask-item-form_active):hover{
      background-color: $color-ghost-white;

      .btn-close{
        display: block;
      }
    }
  }

  .subtask-form-icon,
  .subtask-checkbox{
    background: none;
    border: none;
    padding-right: 6px;
  }

  .subtask-item-form-add_item {
    font-size: 14px;
    background: #fff;
    padding: 6px;
    border-radius: 5px;
    //height: 34px;
    border: none;
    padding-left: 12px;

    &::placeholder {
      color: $color-dark-gray;
      opacity: 1; /* Firefox */
    }
    
    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: $color-dark-gray;
    }


    &:empty::before {
      content: attr(placeholder);
      color: $color-dark-gray;
    }
  }

  &.subtask-item-form-button{
    .subtask-checkbox{
      visibility: hidden;
    }
  }
}

.subtask-form,
.subtask-item-form{
  .form-control{
    //height: 34px !important;
    font-size: 14px;
    border-radius: 5px !important;
    border: none !important;

    
    &.subtask-item-form-add_item{
      height: auto;
      color: $color-dark-gray;

      &::placeholder {
        color: $color-dark-gray;
        opacity: 1; /* Firefox */
      }
      
      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: $color-dark-gray;
      }
    }
  }
}

.subtask {
  border-bottom: 1px solid #ddd;

  .subtask-title{
    padding: 6px;
    font-weight: 700;
    font-size: 14px;
    background: none;
    min-height: 33px;
    word-break: break-all;
  }

  .subtask-buttons{
    min-width: 200px;
  }

  .subtask-item{
    .subtask-checkbox{
      background: none;
      border: none;
      padding-right: 6px;
    }

    .subtask-item-description{
      word-break: break-all;
      font-size: 14px;
      background: none;
      padding: 6px;
      border-radius: 5px;
      //height: 34px;
      border: none;
      padding-left: 8px;
      padding-right: 32px;
      height: initial;

      &.subtask-done{
        color: $color-dark-gray;
      }
    }
  }
}

.subtask-progress-container{
  padding-left: 12px;
  padding-right: 12px;

  label{
    margin-right: 6px;
    font-weight: 500;
    font-size: 10px;
    color: $color-dark-gray !important;
  }

  .progress-bar{
    background-color: $color-dark-gray;

    &[aria-valuenow="100"]{
      background-color: $color-lawn-green;
    }
  }

}